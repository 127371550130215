$midnight: #2c3e50;
$clouds: #ecf0f1;
// Hide elements, for screen readers only
.screen-reader {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Reset lists style
.list-reset {
    list-style: none;
    padding: 0;
    li {
        margin: 0;
    }
}

// Text alignment
.ta-l {
    text-align: left;
}

.ta-c {
    text-align: center;
}

.ta-r {
    text-align: right;
}

// Font weight
.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

// Text color
.text-color-high {
    color: color(high-contrast);
}

.text-color-mid {
    color: color(mid-contrast);
}

.text-color-low {
    color: color(low-contrast);
}

.text-color-primary {
    color: color(primary);
}

.text-color-secondary {
    color: color(secondary);
}

.text-color-error {
    color: color(error);
}

.text-color-warning {
    color: color(warning);
}

.text-color-success {
    color: color(success);
}

.invert-color {
    .text-color-high {
        color: color(high-contrast-inverse);
    }
    .text-color-mid {
        color: color(mid-contrast-inverse);
    }
    .text-color-low {
        color: color(low-contrast-inverse);
    }
}

// Text transform
.tt-u {
    text-transform: uppercase;
}

// Images
.image-full {
    width: 100%;
}

.image-larger {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + #{$image-larger--extra-width * 2});
    max-width: 100vw;
}

// Helper class for coloured bg
.has-bg-color {
    position: relative;
    background-color: color-bg(bg-color);
    z-index: 0;
}

// Helper class for box shadow
.has-shadow {
    box-shadow: color-bg(shadow);
}

img.has-shadow {
    border-radius: $img-radius;
}

// Dividers
.has-top-divider {
    @include divider(before);
}

.has-bottom-divider {
    @include divider(after);
}

.invert-color {
    .has-top-divider {
        @include divider(before, inverse);
    }
    .has-bottom-divider {
        @include divider(after, inverse);
    }
}

// For centering content
.center-content {
    text-align: center;
    img,
    svg,
    video {
        margin-left: auto;
        margin-right: auto;
    }
    .button-group {
        justify-content: center;
    }
}

// Responsive video
.responsive-video {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    height: 0;
    &.is-4-3 {
        padding-bottom: 75%; // 4:3
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Spacing, margin
.m-0 {
    margin: 0;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.m-4 {
    margin: 4px;
}

.mt-4 {
    margin-top: 4px;
}

.mr-4 {
    margin-right: 4px;
}

.mb-4 {
    margin-bottom: 4px;
}

.ml-4 {
    margin-left: 4px;
}

.m-8 {
    margin: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.mb-8 {
    margin-bottom: 8px;
}

.ml-8 {
    margin-left: 8px;
}

.m-12 {
    margin: 12px;
}

.mt-12 {
    margin-top: 12px;
}

.mr-12 {
    margin-right: 12px;
}

.mb-12 {
    margin-bottom: 12px;
}

.ml-12 {
    margin-left: 12px;
}

.m-16 {
    margin: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.ml-16 {
    margin-left: 16px;
}

.m-24 {
    margin: 24px;
}

.mt-24 {
    margin-top: 24px;
}

.mr-24 {
    margin-right: 24px;
}

.mb-24 {
    margin-bottom: 24px;
}

.ml-24 {
    margin-left: 24px;
}

.m-32 {
    margin: 32px;
}

.mt-32 {
    margin-top: 32px;
}

.mr-32 {
    margin-right: 32px;
}

.mb-32 {
    margin-bottom: 32px;
}

.ml-32 {
    margin-left: 32px;
}

// Spacing, padding
.p-0 {
    padding: 0;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.p-4 {
    padding: 4px;
}

.pt-4 {
    padding-top: 4px;
}

.pr-4 {
    padding-right: 4px;
}

.pb-4 {
    padding-bottom: 4px;
}

.pl-4 {
    padding-left: 4px;
}

.p-8 {
    padding: 8px;
}

.pt-8 {
    padding-top: 8px;
}

.pr-8 {
    padding-right: 8px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pl-8 {
    padding-left: 8px;
}

.p-12 {
    padding: 12px;
}

.pt-12 {
    padding-top: 12px;
}

.pr-12 {
    padding-right: 12px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pl-12 {
    padding-left: 12px;
}

.p-16 {
    padding: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.pr-16 {
    padding-right: 16px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pl-16 {
    padding-left: 16px;
}

.p-24 {
    padding: 24px;
}

.pt-24 {
    padding-top: 24px;
}

.pr-24 {
    padding-right: 24px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pl-24 {
    padding-left: 24px;
}

.p-32 {
    padding: 32px;
}

.pt-32 {
    padding-top: 32px;
}

.pr-32 {
    padding-right: 32px;
}

.pb-32 {
    padding-bottom: 32px;
}

.pl-32 {
    padding-left: 32px;
}

// Spacing, spacers
.spacer-4 {
    padding-top: 4px;
}

.spacer-8 {
    padding-top: 8px;
}

.spacer-12 {
    padding-top: 12px;
}

.spacer-16 {
    padding-top: 16px;
}

.spacer-24 {
    padding-top: 24px;
}

.spacer-32 {
    padding-top: 32px;
}

.spacer-48 {
    padding-top: 48px;
}

.spacer-64 {
    padding-top: 64px;
}

@include media( '<=medium') {
    .ta-l-mobile {
        text-align: left;
    }
    .ta-c-mobile {
        text-align: center;
    }
    .ta-r-mobile {
        text-align: right;
    }
    .center-content-mobile {
        text-align: center;
        img,
        svg,
        video {
            margin-left: auto;
            margin-right: auto;
        }
        .button-group {
            justify-content: center;
        }
    }
    // Spacing, spacers
    .spacer-4-mobile {
        padding-top: 4px;
    }
    .spacer-8-mobile {
        padding-top: 8px;
    }
    .spacer-12-mobile {
        padding-top: 12px;
    }
    .spacer-16-mobile {
        padding-top: 16px;
    }
    .spacer-24-mobile {
        padding-top: 24px;
    }
    .spacer-32-mobile {
        padding-top: 32px;
    }
    .spacer-48-mobile {
        padding-top: 48px;
    }
    .spacer-64-mobile {
        padding-top: 64px;
    }
}

@include media( '>medium') {
    .ta-l-desktop {
        text-align: left;
    }
    .ta-c-desktop {
        text-align: center;
    }
    .ta-r-desktop {
        text-align: right;
    }
    .center-content-desktop {
        text-align: center;
        img,
        svg,
        video {
            margin-left: auto;
            margin-right: auto;
        }
        .button-group {
            justify-content: center;
        }
    }
}

.font-24 {
    font-size: 24px;
}

.w-7 {
    width: 70px;
}

.justify-end {
    justify-content: flex-end;
}

.column {
    flex-direction: column;
}


/* Accordion styles */

.tabs {
    // border-radius: 8px;
    overflow: hidden;
    // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
    width: 100%;
    margin-top: 19px;
    // color: white;
    font: normal normal normal 24px/36px Lato;
    letter-spacing: 0.19px;
    color: #000000;
    font-size: 24px;
    overflow: hidden;
    &-label {
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
        // padding: 1em;
        // background: $midnight;
        // font-weight: bold;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #BB955E;
        border-radius: 10px;
        cursor: pointer;
        /* Icon */
        &:hover {
            background: #BB955E;
        }
        &::after {
            content: "+";
            text-align: center;
            transition: all 0.35s;
            color: white;
            background: #BB955E;
            border-radius: 100%;
            width: 37px !important;
            height: 37px !important;
            font-size: 40px;
        }
    }
    &-content {
        max-height: 0;
        padding: 0 1em;
        // color: $midnight;
        // background: white;
        transition: all .35s;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 0 0 10px 10px;
        font-size: 18px;
    }
    &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: $midnight;
        cursor: pointer;
        &:hover {
            background: darken($midnight, 10%);
        }
    }
}

.terms-check {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

// :checked
input:checked {
    +.tab-label {
        // background: darken($midnight, 10%);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        // border: 1px solid #BB955E;
        border-bottom: none;
        border-radius: 10px 10px 0 0;
        // border-radius: 10px;
        &::after {
            // transform: rotate(90deg);
            content: "-";
        }
    }
    ~.tab-content {
        white-space: pre-line;
        max-height: 100vh;
        padding: 0 30px;
        overflow-y: auto;
        border: 1px solid #BB955E;
        border-top: none;
    }
}

.pointer {
    cursor: pointer;
}

.white {
    color: white;
}

.h-9vh {
    min-height: 90vh;
}

.underline {
    text-decoration: underline;
}

.menu-title {
    font-size: 18px !important;
    color: white !important;
    font: normal normal bold 18px/36px Libre Baskerville;
    letter-spacing: 0.14px;
}

.header-container {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/BG-Header.png');
    // height: calc(100vw * 0.56);
    height: 767px;
}

.menu-container {
    margin: 24px 55px 0 auto;
}

.thestorycontainer {
    margin-top: 60px;
}

.thestorytext {
    width: 50%;
    margin-top: 32px;
}

.thestorytext p {
    text-align: left;
    font: normal normal normal 25px/35px Lato;
    letter-spacing: 0.2px;
    color: #313542;
    margin-bottom: 16px;
}

.production-container {
    background: #BB955E 0% 0% no-repeat padding-box;
    padding: 90px 79px 75px 75px;
}

.production-title {
    font-size: 40px !important;
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 0px;
}

.production-desc {
    font-size: 25px;
    text-align: left;
    font: normal normal normal 25px/35px Lato;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    opacity: 1;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.video-wrapper {
    margin-top: 82px;
}

.video-label-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 8px;
}

.video-label {
    font-size: 22px;
    text-align: center;
    font: normal normal normal 22px/36px Libre Baskerville;
    letter-spacing: 0.18px;
    color: #FFFFFF;
    opacity: 1;
}

.video-player {
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 50px);
}

.involved-container {
    padding: 90px 75px 40px 75px;
}

.involved-title {
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #313542;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 60px;
}

.involved-item {
    margin: 0px 24px 48px 16px;
}

.involved-item img {
    width: 90px;
}

.involved-p {
    text-align: left;
    font: normal normal normal 25px/36px Lato;
    letter-spacing: 0.2px;
    color: #313542;
    opacity: 1;
    font-size: 25px;
    margin-left: 60px;
    margin-right: 10px;
    margin-bottom: 0;
}

.inclusion-container {
    padding: 90px 75px 20px 75px;
    background: #313542 0% 0% no-repeat padding-box;
}

.inclusion-title {
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #BB955E;
    opacity: 1;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 80px;
}

.inclusion-p {
    text-align: left;
    font: normal normal normal 25px/36px Lato;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    font-size: 25px;
    padding: 48px 26px;
}

.m-auto {
    margin: auto;
}

.grid-3 {
    display: grid;
    grid-template-columns: 33% 34% 33%;
}

.faq-container {
    padding: 90px 69px;
}

.faq-title {
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #313542;
    font-size: 40px;
    margin-bottom: 20px;
}

.pricing-title {
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #313542;
    font-size: 40px;
    margin-bottom: 17px;
    color: white;
}

.pricing-contaienr {
    background-color: #BB955E;
    padding: 90px 75px 70px 75px;
}

.pricing-desc {
    font-size: 24px;
    text-align: left;
    font: normal normal normal 24px/36px Lato;
    letter-spacing: 0.18px;
    color: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 20px;
}

.pricing-table {
    border-radius: 10px;
    background-color: white;
}

.pricing-table thead {
    background: #313542 0% 0% no-repeat padding-box;
    height: 56px;
    text-align: center;
    font: normal normal bold 20px/36px Lato;
    letter-spacing: 0.16px;
    color: #BB955E;
    // text-transform: uppercase;
}

.pricing-table th,
.pricing-table td {
    text-align: center;
}

.pricing-table tbody tr {
    border-bottom: 1px solid #BB955E;
    height: 62px;
    letter-spacing: 0.16px;
    color: #000000;
    font-size: 20px;
}

.first-row {
    background: #bb945e4a 0% 0% no-repeat padding-box;
}

.last-row {
    border-bottom: none !important;
}

.td-terms {
    text-align: left !important;
    padding-left: 32px;
}

.bestvalue {
    width: 148px;
    height: 39px;
    background: transparent linear-gradient(270deg, #F2D987 0%, #E1BD65 90%, #A07530 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    font: normal normal bold 20px/36px Lato;
    letter-spacing: 0.16px;
    color: #313542;
    font-size: 20px;
    padding: 8px 24px;
    margin-left: 12px;
}

.contact-container {
    // background: transparent url('/BG-ReadyToTell.png') 0% 0% no-repeat padding-box;
    background: #313542;
    overflow: hidden;
}

.contact-left {
    padding-top: 90px;
    padding-left: 75px;
    padding-bottom: 75px;
}

.contact-right {
    width: 50%;
    min-height: 20px;
    background: transparent url('/contact-image-v2-transparent.png') 0% 0% no-repeat padding-box;
    position: absolute;
    right: 0;
    top: 160px;
    height: 100%;
    z-index: -1;
}

.contact-title {
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    font-size: 40px;
}

.contact-line {
    margin-top: 90px;
    margin-bottom: 60px;
    width: 699px;
    height: 0px;
    border: 1px solid #BB955E;
}

.contact-p {
    text-align: left;
    font: normal normal normal 25px/36px Lato;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    font-size: 25px;
}

.contact-input {
    width: 698px;
    height: 62px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BB955E;
    border-radius: 10px;
    text-align: left;
    font: normal normal normal 24px/36px Lato;
    letter-spacing: 0.19px;
    color: #000000;
    font-size: 24px;
}

.mt-1 {
    margin-top: 20px;
}

.footer-copyright {
    font: normal normal normal 11px/36px Lato;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    font-size: 11px;
}

.footer-bottom {
    font: normal normal normal 13px/36px Lato;
    letter-spacing: 0.1px;
    font-size: 13px;
    color: #BB955E;
}

.pointer {
    cursor: pointer;
}

.toTop {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 100%;
}

.termsModal .modal-inner {
    border-radius: 30px;
    max-width: 794px;
}

.termsModal .modal-close {
    background-color: #BB955E;
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

@media (min-width: 641px) {
    .termsModal .modal-content {
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 64px;
        padding-right: 64px;
    }
}

.termsModal p {
    text-align: left;
    font: normal normal normal 20px/24px Lato;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    font-size: 20px;
    margin-bottom: 14px;
}

.termsModal .modal-close::before,
.termsModal .modal-close::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -11px;
    width: 24px;
    height: 2px;
    background: #444444;
}

.terms-title {
    text-align: left;
    font: normal normal bold 40px/36px Libre Baskerville;
    letter-spacing: 0.32px;
    color: #BB955E;
    font-size: 40px;
    margin-bottom: 21px;
}

.cpt-captcha {
    margin-top: 16px;
}

.form-hint {
    color: red;
}

.thestory-image {
    display: block;
}

@media (max-width: 1280px) {
    .video-label-wrapper {
        margin-bottom: 16px;
    }
    .thestorycontainer {
        margin-top: 0px;
        background-image: url('/Img_About.png');
        background-repeat: no-repeat;
        padding: 24px 36px;
    }
    .thestory-image {
        display: none;
    }
    .thestorytext {
        width: 100%;
    }
    .production-container {
        padding: 90px 39px 75px 35px;
    }
    .video-wrapper {
        flex-direction: column;
        margin-top: 42px;
    }
    .video-wrapper img {
        margin: auto;
    }
    .involvedContentWrapper {
        flex-direction: column;
    }
    .grid-3 {
        display: grid;
        grid-template-columns: 100%;
    }
    .contact-right {
        display: none;
    }
}

@media (max-width: 840px) {
    .contact-left {
        padding-top: 50px;
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 35px;
    }
    .contact-title {
        font-size: 32px;
    }
    .contact-input,
    .cta-action {
        width: 100%;
    }
    .contact-line {
        width: 100%;
        margin-top: 60px;
        margin-bottom: 30px;
    }
    .cpt-captcha {
        display: flex;
        justify-content: center;
    }
    .btnLearnMore {
        margin-top: 25px;
    }
}

@media (max-width: 640px) {
    .contact-title {
        font-size: 28px;
    }
    .thestorycontainer {
        background-position: bottom;
    }
    .term-bar {
        display: none;
    }
    .involved-container {
        padding: 90px 35px 40px 35px;
    }
    .involved-item {
        flex-direction: column;
        margin: 0px 14px 28px 0px;
    }
    .involved-p {
        margin-left: 0;
    }
    .involved-item img {
        margin: auto;
    }
    .production-title,
    .involved-title {
        font-size: 32px !important;
    }
    .inclusion-container {
        padding: 90px 35px 20px 35px;
    }
    .inclusion-p {
        padding: 8px 6px;
        font-size: 20px;
    }
    .inclusion-image img {
        width: 60px;
    }
    .faq-container {
        padding: 50px 24px;
    }
    .tab {
        &-label {
            &::after {
                color: #BB955E;
                background: rgba(255, 255, 255, 0);
                width: 27px !important;
                height: 27px !important;
                font-size: 30px;
            }
        }
    }
    .pricing-contaienr {
        padding: 90px 35px 70px 35px;
    }
    .menu-container {
        display: none;
    }
    .header-title-wrapper {
        margin-bottom: 70px;
    }
    .header-title {
        font-size: 40px !important;
        line-height: 1.5 !important;
    }
    .btnLearnMoreHome {
        width: 265px !important;
        display: none;
    }
    .btnLearnMore {
        width: 100%;
    }
    .header-container {
        height: auto;
        padding-bottom: 50px;
    }
    .footer-top>*:not(:last-child),
    .footer-bottom>*:not(:last-child) {
        margin-bottom: 0px;
    }
    .inclusion-title {
        margin-bottom: 50px;
    }
    #contact-modal .responsive-video {
        padding-bottom: 80vh;
    }
    .logo {
        position: absolute;
        top: 29px;
        left: 45px;
    }
    .logo {
        width: 80px;
    }
    .thestorytext p {
        font-weight: 600;
    }
}

.pricing-table-content {
    overflow-x: auto;
}

.pricing-table {
    min-width: 900px;
}