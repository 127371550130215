.site-footer {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: color-bg(footer);
        z-index: -3;
    }
}

.site-footer-inner {
    padding: 26px 0;
    // padding: $footer--padding__mobile 0;
}

.footer-top {
    padding-bottom: $footer-inner--padding-v * 0.5;
}

.footer-bottom {
    padding-top: $footer-inner--padding-v * 0.5;
}

.footer-blocks {
    display: flex;
    flex-wrap: wrap;
    margin-right: -($footer-block--padding_h * 0.5);
    margin-left: -($footer-block--padding_h * 0.5);
    margin-top: -($footer-block--padding_v * 0.5);
    &:last-of-type {
        margin-bottom: -($footer-block--padding_v * 0.5) + $footer-blocks--padding;
    }
    &:not(:last-of-type) {
        margin-bottom: ($footer-block--padding_v * 0.5);
    }
}

.footer-block {
    flex-grow: 1;
    flex-basis: $footer-block--min-width;
    box-sizing: content-box;
    padding: $footer-block--padding_v * 0.5 $footer-block--padding_h * 0.5;
    * {
        box-sizing: border-box;
    }
    ul li {
        &:not(:last-child) {
            margin-bottom: $footer-block-list-padding;
        }
    }
    a {
        @include anchor-aspect(footer-block);
        @include font-weight(footer-block-link);
        text-transform: $link-footer-block--transform;
        .invert-color & {
            @include anchor-aspect(footer-block, inverse);
        }
    }
}

.footer-block-title {
    @include font-size(block-title);
    @include font-weight(block-title);
    color: color(block-title);
    text-transform: $footer-block-title--transform;
    margin-bottom: $footer-block-title-padding;
    .invert-color & {
        color: color(block-title-inverse);
    }
}

.footer-social,
.footer-nav {
    a {
        display: flex;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        margin-bottom: 0;
    }
}

.footer-social {
    ul {
        margin-right: -($footer-social--inner-padding + $footer-social--outer-padding-h);
        margin-left: -($footer-social--inner-padding + $footer-social--outer-padding-h);
    }
    li {
        padding: 0 $footer-social--outer-padding-h;
    }
    a {
        padding: $footer-social--inner-padding;
        svg {
            fill: color-icon(social);
            transition: fill .15s ease;
            .invert-color & {
                fill: color-icon(social-inverse);
            }
        }
        &:hover {
            svg {
                fill: color-icon(social-hover);
                .invert-color & {
                    fill: color-icon(social-hover-inverse);
                }
            }
        }
    }
}

.footer-nav {
    margin-right: -$footer-nav--padding-h;
    margin-left: -$footer-nav--padding-h;
    a {
        @include anchor-aspect(footer);
        @include font-weight(footer-link);
        text-transform: $link-footer--transform;
        padding: 0 $footer-nav--padding-h;
        .invert-color & {
            @include anchor-aspect(footer, inverse);
        }
    }
}

@include media( '<=medium') {
    .footer-top,
    .footer-bottom {
        >*:not(:last-child) {
            margin-bottom: $footer-inner--padding-v;
        }
    }
}

@include media( '>medium') {
    @if ( $footer--padding__mobile !=$footer--padding__desktop) {
        .site-footer-inner {
            // padding: $footer--padding__desktop 0;
            padding: 26px 0;
        }
    }
    .footer-top,
    .footer-bottom {
        &.space-between {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .footer-top,
    .footer-bottom {
        &.invert-order-desktop {
            >*:first-child {
                order: 1;
            }
        }
    }
}